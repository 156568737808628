import React from "react";
import { makeStyles } from "../AppContainer/mui-theme";
import {
  Grid,
  Typography,
  CardMedia,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";

import validator from "validator";
import Button from "../Button";
import { isEmpty, get } from "lodash";
import { LOGIN_TYPES } from "../LoginView";
import getPromotionImage from "../../utils/get-promotion-image";
import PromotionMedia from "./promotion-media";

export default function Promotion({
  promotion,
  appStyles,
  navigateToTargetOrLoginMobile,
  navigateToTargetOrLoginDesktop,
  handleStartNewOrderClicked,
}) {
  const { classes } = useStyles();
  const targetType = get(promotion, "buttonTarget.targetType");
  const isExternalLink = targetType === "web";
  const externalLinkTargetUrl =
    isExternalLink && get(promotion, "buttonTarget.targetValue");
  const showLinkButton =
    !isEmpty(promotion.buttonText) &&
    !isEmpty(get(promotion, "buttonTarget.targetType")) &&
    (isExternalLink
      ? validator.isURL(externalLinkTargetUrl || "", {
          protocols: ["https", "tel", "http", "sms"],
        })
      : true);

  const handleClick = (e) => {
    console.log(e);
    if (targetType === "rewards") {
      navigateToTargetOrLoginMobile({
        type: LOGIN_TYPES.GIFTS,
        target: "/gifts",
      })(e);
    }
    if (targetType === "order") {
      handleStartNewOrderClicked(e);
    }
    if (targetType === "subscription") {
      navigateToTargetOrLoginMobile({
        type: LOGIN_TYPES.SUBSCRIPTION,
        target: "/subscriptions",
      })(e);
    }
  };
  const buttonProps = {
    ...(!isExternalLink && { onClick: handleClick }),
  };
  const promotionImage = getPromotionImage(promotion);
  return (
    <Card
      className={classes.root}
      style={{ borderRadius: appStyles.cardBorderRadius }}
    >
      <PromotionMedia promotion={promotion} />
      {(!isEmpty(promotion.title) ||
        !isEmpty(promotion.subtitle) ||
        !isEmpty(promotion.content)) && (
        <CardContent className={classes.content}>
          {!isEmpty(promotion.title) && (
            <Typography gutterBottom variant="h2" component="h2">
              {promotion.title}
            </Typography>
          )}
          {!isEmpty(promotion.subtitle) && (
            <Typography gutterBottom variant="caption" component="h3">
              {promotion.subtitle}
            </Typography>
          )}
          {!isEmpty(promotion.content) && (
            <Typography variant="body1" component="p">
              {promotion.content}
            </Typography>
          )}
        </CardContent>
      )}

      {showLinkButton && (
        <CardActions disableSpacing>
          <Grid container justifyContent="flex-end">
            <Grid item style={get(promotion,"fullWidthButton") ? {width:"100%", justifyContent: "center"} : {}}>
              <Button
                {...buttonProps}
                isExternalLink={isExternalLink}
                to={externalLinkTargetUrl}
                appStyles={appStyles}
                style={get(promotion,"fullWidthButton") ? {width:"100%", justifyContent: "center", paddingLeft: "0px", paddingRight: "0px"} : {}}>
                {promotion.buttonText}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}

const useStyles = makeStyles()((theme) => ({
  media: {
    height: `calc(100vw - ${theme.spacing(4)})`,
    width: `calc(100vw - ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      height: `calc(376px - ${theme.spacing(4)})`,
      width: `calc(376px - ${theme.spacing(4)})`,
    },
  },
  content: {
    boxShadow: "none",
    border: "0px"
  },
}));
